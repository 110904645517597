import React, { useEffect, useState } from 'react';
import { Layout, Menu, Card, Statistic, Table, Divider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, DashboardOutlined, TeamOutlined, LineChartOutlined, MinusOutlined } from '@ant-design/icons';
import io from 'socket.io-client';
import './ManagerDashboard.css';

const { Sider, Content } = Layout;

const ManagerDashboard = () => {
    const [averageWaitTimes, setAverageWaitTimes] = useState({
        order: { current_avg: 0, previous_avg: 0 },
        payment: { current_avg: 0, previous_avg: 0 },
        pickup: { current_avg: 0, previous_avg: 0 }
    });

    const [managerInsights, setManagerInsights] = useState([]);

    useEffect(() => {
        const socket = io('http://44.210.104.161:80', { transports: ['websocket', 'polling'] });

        socket.on('connect', () => {
            console.log('Connected to WebSocket for Manager Dashboard');
        });

        socket.on('average_wait_times', (data) => {
            setAverageWaitTimes((prevWaitTimes) => {
                const updatedWaitTimes = { ...prevWaitTimes };
                Object.keys(data).forEach((station) => {
                    updatedWaitTimes[station] = {
                        previous_avg: updatedWaitTimes[station].current_avg,
                        current_avg: data[station].current_avg
                    };
                });
                return updatedWaitTimes;
            });
        });

        socket.on('manager_insights', (insights) => {
            if (Array.isArray(insights)) {
                setManagerInsights(insights);
            }
        });

        socket.on('disconnect', () => {
            console.log('WebSocket for Manager Dashboard disconnected');
        });

        return () => socket.disconnect();
    }, []);

    const columns = [
        {
            title: 'Station',
            dataIndex: 'station',
            key: 'station',
        },
        {
            title: 'Suggested Action',
            dataIndex: 'suggested_action',
            key: 'suggested_action',
        },
    ];

    const getTrendIcon = (previousAvg, currentAvg) => {
        if (currentAvg > previousAvg) {
            return <ArrowUpOutlined style={{ color: '#cf1322' }} />;
        } else if (currentAvg < previousAvg) {
            return <ArrowDownOutlined style={{ color: '#3f8600' }} />;
        } else {
            return <MinusOutlined style={{ color: '#d3d3d3' }} />;
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider className="sidebar" width={260}>
                <div className="logo">Manager Dashboard</div>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['2']}
                    items={[
                        { key: '1', label: 'Operator Dashboard', icon: <DashboardOutlined /> },
                        { key: '2', label: 'Manager Dashboard', icon: <TeamOutlined /> },
                        { key: '3', label: 'HQ Dashboard', icon: <LineChartOutlined /> },
                    ]}
                />
            </Sider>
            <Content className="content">
                <h2 className="dashboard-title">Manager Dashboard</h2>
                <div className="dashboard-container">
                    <div className="average-time-cards">
                        <Card className="dashboard-card">
                            <Statistic
                                title="Average Wait Time at Order Station"
                                value={averageWaitTimes.order.current_avg.toFixed(2)}
                                suffix="sec"
                                valueStyle={{ color: averageWaitTimes.order.current_avg > 60 ? '#cf1322' : '#3f8600' }}
                                prefix={getTrendIcon(
                                    averageWaitTimes.order.previous_avg,
                                    averageWaitTimes.order.current_avg
                                )}
                            />
                        </Card>
                        <Card className="dashboard-card">
                            <Statistic
                                title="Average Wait Time at Payment Station"
                                value={averageWaitTimes.payment.current_avg.toFixed(2)}
                                suffix="sec"
                                valueStyle={{ color: averageWaitTimes.payment.current_avg > 60 ? '#cf1322' : '#3f8600' }}
                                prefix={getTrendIcon(
                                    averageWaitTimes.payment.previous_avg,
                                    averageWaitTimes.payment.current_avg
                                )}
                            />
                        </Card>
                        <Card className="dashboard-card">
                            <Statistic
                                title="Average Wait Time at Pickup Station"
                                value={averageWaitTimes.pickup.current_avg.toFixed(2)}
                                suffix="sec"
                                valueStyle={{ color: averageWaitTimes.pickup.current_avg > 60 ? '#cf1322' : '#3f8600' }}
                                prefix={getTrendIcon(
                                    averageWaitTimes.pickup.previous_avg,
                                    averageWaitTimes.pickup.current_avg
                                )}
                            />
                        </Card>
                    </div>
                    <Divider />
                    <h3>Manager Insights</h3>
                    <Table
                        columns={columns}
                        dataSource={managerInsights}
                        rowKey="station"
                        pagination={false}
                        bordered
                        className="insights-table"
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default ManagerDashboard;
